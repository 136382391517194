<template>
  <div id="product-select-slide">
    <slide-heading
      :heading="currentSlide.heading"
    />

    <product-options-confirm-modal
      :bus="bus"
      :loading="false"
      @complete="productOptionConfirmed"
      @productAdded="productAddedViaModal"
    />

    <template v-if="loaded">
      <div class="slide-description mb-2 justify-content-center">
        <div v-html="currentSlide.description" />
      </div>

      <div class="product-grid">
        <div class="requires-attention-icon-div ">
          <p v-if="hasThreeProducts" class="requires-attention-icon-text">
            Recommended
          </p>
        </div>
        <div
          v-for="(product, index) in slideProducts"
          :key="product.id"
          :class="[
            hasThreeProducts && index === 0 ? 'premium-product' : 'free-product',
            hasThreeProducts ? 'full-width' : 'standard-width',
          ]"
        >
          <div
            class="product-option"
            @click="selectProductStage(product.product_sub_category)"
          >
            <div class="picture">
              <picture>
                <source
                  :srcset="getProductImage(product.product_sub_category, 'webp')"
                  type="image/webp"
                >
                <img
                  :src="getProductImage(product.product_sub_category, 'png')"
                  alt="alt-image"
                  class="image"
                >
              </picture>
            </div>
            <div class="product-text">
              <h3>
                {{ product.title }}
              </h3>
              <div v-html="product.description" />
            </div>
          </div>
        </div>

        <b-button
          class="no-product"
          variant="default"
          aria-label="nothing needed button"
          @click="skipDocs"
        >
          {{ skipText() }}
          <fa-icon icon="chevron-right" class="fa-xs" />&nbsp;
        </b-button>
      </div>
    </template>

    <ct-centered-spinner v-else>
      {{ text.loadingText }}
    </ct-centered-spinner>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { logSlideProductAdded } from '@/components/StagelineV2/helper.js'
import LeanBusinessPng      from '@images/illustrations/stagelineV2/lean_business_plan.png'
import LeanBusinessWebp     from '@images/illustrations/stagelineV2/lean_business_plan.webp'
import OnePageBusinessPng   from '@images/illustrations/stagelineV2/one_page_business_plan.png'
import OnePageBusinessWebp  from '@images/illustrations/stagelineV2/one_page_business_plan.webp'
import PremiumDocumentsPng  from '@images/illustrations/stagelineV2/premium_documents.png'
import PremiumDocumentsWebp from '@images/illustrations/stagelineV2/premium_documents.webp'
import PrintDocumentsPng    from '@images/illustrations/stagelineV2/print_documents.png'
import PrintDocumentsWebp   from '@images/illustrations/stagelineV2/print_documents.webp'
import UploadYourOwnPng     from '@images/illustrations/stagelineV2/upload_your_own.png'
import UploadYourOwnWebp    from '@images/illustrations/stagelineV2/upload_your_own.webp'
import EinPng               from '@images/illustrations/stagelineV2/ein.png'
import EinWebp              from '@images/illustrations/stagelineV2/ein.webp'


export default {
  name: 'ProductOptionsSlide',
  components: {
    ProductOptionsConfirmModal: () => import('@/components/StagelineV2/modals/ProductOptionsConfirmModal'),
    CtCenteredSpinner:          () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:               () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slideProducts: Array,
  },
  data() {
    return {
      loaded: false,
      consultProduct: {},
      text: {
        loadingText: 'Loading products...',
        skipText: {
          finalizeLegalDocs: 'My business does not need any internal legal documents at this time.',
          businessPlan: 'My business plan is already taken care of',
          ein: 'My business doesn`t need an EIN at this time.',
        },
      },
      productImages: {
        finalize_legal_docs: {
          premium: {
            png: PremiumDocumentsPng,
            webp: PremiumDocumentsWebp,
          },
          'free basic': {
            png: PrintDocumentsPng,
            webp: PrintDocumentsWebp,
          },
        },
        business_plan: {
          'lean': {
            png: LeanBusinessPng,
            webp: LeanBusinessWebp,
          },
          'one page': {
            png: OnePageBusinessPng,
            webp: OnePageBusinessWebp,
          },
        },
        ein: {
          png: EinPng,
          webp: EinWebp,
        },
        diy: {
          png: UploadYourOwnPng,
          webp: UploadYourOwnWebp,
        },
      },
      bus: new Vue(),
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
      'company',
      'stageProductSelectedType',
      'purchasedLegalDocs',
    ]),
    ...mapGetters('checkout', [
      'allProducts',
      'cartItems',
    ]),
    hasThreeProducts() {
      return this.slideProducts?.length === 3
    },
  },
  async mounted() {
    if (this.isFinalizeLegalDocs() && this.purchasedLegalDocs) {
      this.$emit('next-slide')
    } else {
      this.loaded = true
      await this.loadCurrentProductAndRemoveFromCart()
      this.$store.commit('stageline/set_company_stage_data', {})
    }
  },
  methods: {
    ...mapActions('stageline', [
      'setProductType',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
      'removeFromCart',
    ]),
    async selectProductStage(product) {
      this.loadingText = 'Loading...'
      this.loaded = false
      if (product === 'premium' && !this.purchasedPremiumOption())  {
        await this.addToCart(this.consultProduct)
        await logSlideProductAdded(this.consultProduct)
      }

      await this.setProductType(product)

      if (product === 'tax id') {
        await this.loadProductOptionsModal()
      }
      else {
        this.$emit('next-slide')
      }

      this.loaded = true
    },
    async loadProductOptionsModal() {
      if (!this.slideProducts.length > 0) {
        this.error()
        return
      }

      const hireUsProduct = this.slideProducts.find(product => product.product_kind!== 'diy')

      const hireUsProducts = await this.fetchProduct({
        productKind: hireUsProduct.product_kind,
        productCategory: hireUsProduct.product_category,
        jurisdictionId: this.company.domestic_registration?.jurisdiction_id,
      })

      if (!hireUsProducts.length) {
        this.error()
        return
      }

      this.bus.$emit('open-product-options-confirm', { products: hireUsProducts })
    },
    async loadCurrentProductAndRemoveFromCart() {
      const productCategory = this.slideProducts[0].product_category
      this.consultProduct = this.allProducts.find(p => p.category === productCategory)
      this.setProductData()

      if (this.consultProduct) {
        const currentProductInCart = this.cartItems.find(p => p.title === this.consultProduct.name)
        if (currentProductInCart) await this.removeFromCart({ id: currentProductInCart.id })
      }
    },
    setProductData() {
      // Data needed on the checkout side, in this case, setting the jurisdiction so the invoice
      // shows the correct jurisdiction
      if (this.consultProduct) {
        this.consultProduct['data'] = {}
        this.consultProduct.data['jurisdiction_id'] = this.company.domestic_registration?.jurisdiction_id
      }
    },
    skipDocs() {
      this.setProductType('skip')
      this.$emit('next-slide')
    },
    skipText() {
      switch(true) {
        case this.isEinFiling():
          return this.text.skipText.ein || ''
        case this.isBusinessPlan():
          return this.text.skipText.businessPlan || ''
        case this.isFinalizeLegalDocs():
          return this.text.skipText.finalizeLegalDocs || ''
        default:
          return ''
      }
    },
    getProductImage(subcategory, imageType = 'png') {
      if (subcategory === 'diy') {
        return this.productImages['diy'][imageType]
      }

      switch(true) {
        case this.isEinFiling():
          return this.productImages['ein'][imageType] || ''
        case this.isBusinessPlan():
          return this.productImages['business_plan'][subcategory][imageType] || ''
        case this.isFinalizeLegalDocs():
          return this.productImages['finalize_legal_docs'][subcategory][imageType] || ''
        default:
          return ''
      }
    },
    isEinFiling() {
      return this.slideProducts.some(product => product['product_sub_category'] === 'tax id')
    },
    isBusinessPlan() {
      return this.slideProducts.some(product => product['product_category'] === 'business plan')
    },
    isFinalizeLegalDocs() {
      return ['Operating Agreements', 'Corporate Bylaws'].includes(this.currentSlide.heading)
    },
    purchasedPremiumOption() {
      // TODO add your getters to the stageline store to do lookups on active products
      if (this.isFinalizeLegalDocs()) return this.purchasedLegalDocs
      return false
    },
    productOptionConfirmed() {
      this.$emit('next-slide')
    },
    async productAddedViaModal(productAdded) {
      await logSlideProductAdded(productAdded)
    },
  },
}
</script>

<style scoped lang="scss">
h3 {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: #231F20;
  margin-top: 0;
}

#product-select-slide {
  .product-grid {
    width: 100%;
    display: inline-flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.5em;
  }

  .premium-product:hover, .free-product:hover {
    background-color: #F2FBFA;
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }

  .standard-width {
    width: 50%;
  }

  .premium-product {
    display: flex;
    padding: 2.5em 1.5em;
    justify-content: center;
    align-items: center;
    gap: 2em;
    align-self: stretch;
    border-radius: 0.375em;
    border: 2px solid #00C795;
    background: #FFF;
  }

  .free-product {
    display: flex;
    padding: 1.5em 1em 2em 1.5em;
    align-items: center;
    gap: 2em;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.375em;
    border: 1px solid #CDCDCD;
    background: #FFF;
  }

  .no-product {
    width: 100%;
    font-weight: 700;
    margin-bottom: 2em;
    text-align: center;
  }

  .product-option {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  source, img {
    width: 5.625em;
    height: auto;
    align-self: center;
  }

  .picture {
    display: flex;
    padding-right: 1.5em;
    align-items: center;
  }

  .requires-attention-icon-div {
    width: 100%;
  }

  .requires-attention-icon-text {
    position: relative;
    bottom: -2.5rem;
    color: white;
    background-color: #00C795;
    font-size: 1rem;
    padding: 0.25rem 1.5rem;
    border-radius: 6.25rem;
    font-style: italic;
    width: fit-content;
    margin: auto;
  }
}

@media (max-width: 768px) {
  #product-select-slide {
    .product-grid {
      display: flex;
      flex-direction: column;
    }

    .free-product {
      width: 100%;
    }

    .requires-attention-icon-div {
      height: 0;
    }

    .requires-attention-icon-text {
      bottom: -0.6rem;
    }
  }
}

@media (max-width: 375px) {
  #product-select-slide {
    .product-option {
      display: flex;
      flex-direction: column;
      gap: 1.25em;
      justify-content: center;
      text-align: center;
    }

    .picture {
      padding-right: 0;
    }
  }
}
</style>
